import React, { useEffect, useState, Fragment, useRef } from 'react'
import styled from 'styled-components'
import GraphImg from 'graphcms-image'
import { useMediaQuery } from 'react-responsive'

import P from '../styled/Paragraph'

const Spacer = styled.div`
  /* height: 100vh;
  opacity: 0;
  pointer-events: none; */
`

const Wrapper = styled.section`
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  padding: 130px 15px 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: sticky;
  /* transform: translateZ(0); */
  top: 0;
  background-color: ${({ theme }) => theme.white};

  @media only screen and (min-width: 1024px) {
    opacity: ${({ hideTop }) => (hideTop ? 0 : 1)};
    pointer-events: ${({ hideTop }) => (hideTop ? 'none' : 'auto')};
    padding: 100px 40px;
    min-height: 100vh;
    height: auto;
  }
`

const Paragraph = styled(P)`
  text-align: ${({ right }) => (right ? 'right' : 'left')};
  padding: 25px 0;

  @media only screen and (min-width: 768px) {
    max-width: 60%;
    margin-left: ${({ right }) => (right ? 'auto' : '0')};
  }

  @media only screen and (min-width: 1024px) {
    max-width: 100%;
    padding: 35px 0;
  }
`

const Images = styled.div`
  display: flex;
  flex-direction: column;

  @media only screen and (min-width: 768px) {
    flex-direction: row;
  }
`

const Img = styled.div`
  width: 100%;
  height: calc((100vw - 30px) / 1.6);
  overflow: hidden;
  position: relative;
  height: ${({ show }) => (show ? 'calc((100vw - 30px) / 1.6)' : '0')};

  @media only screen and (min-width: 768px) {
    height: calc((100vw - 60px) / 1.6);
  }

  @media only screen and (min-width: 1024px) {
    height: calc((100vw - 80px) / 5.5);
    display: block;
  }

  .graphcms-image-wrapper {
    & > div:nth-of-type(2) {
      border: 1px solid grey;
    }

    @media only screen and (min-width: 768px) {
      & > div:nth-of-type(2) {
        border: 1px solid grey;
        border-right: 0;
      }
    }
  }

  &:last-of-type {
    .graphcms-image-wrapper {
      & > div:nth-of-type(2) {
        border: 1px solid grey;
      }
    }
  }

  & > div {
    height: inherit;
  }

  & > div > div {
    height: inherit;
  }

  img {
    height: 100%;
    width: 100%;
  }
`

const Top = ({ text1, text2, images }) => {
  const [hideTop, setHideTop] = useState(false)
  const [currentImage, setCurrentImage] = useState(0)
  const interval = useRef(false)

  const isMob = useMediaQuery({
    query: '(max-width: 767px)',
  })

  const handleScroll = () => {
    setHideTop(window.scrollY > window.innerHeight)
  }

  useEffect(() => {
    if (isMob) {
      interval.current = setInterval(() => {
        const newCurr = currentImage === 2 ? 0 : currentImage + 1
        setCurrentImage(newCurr)
      }, 1000)
    }
    return () => {
      if (isMob) {
        clearInterval(interval.current)
      }
    }
  }, [currentImage, isMob])

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)

      if (isMob) {
        clearInterval(interval.current)
      }
    }
  }, [isMob])

  return (
    <Fragment>
      <Spacer />
      <Wrapper hideTop={hideTop} className={'simple-placeholder'}>
        <Paragraph size="l">{text1}</Paragraph>
        <Images>
          {images.map((item, i) => {
            return (
              <Img key={item.handle} show={i === currentImage}>
                <GraphImg
                  image={item}
                  backgroundColor={'transparent'}
                  maxWidth={400}
                  blurryPlaceholder={false}
                ></GraphImg>
              </Img>
            )
          })}
        </Images>
        <Paragraph size="l" right>
          {text2}
        </Paragraph>
      </Wrapper>
    </Fragment>
  )
}

export default Top
