import React from 'react'
import SEO from '../components/seo'
import { graphql } from 'gatsby'

import StickyWrapper from '../components/shared/StickyWrapper'

import Top from '../components/materials/Top'
import Material from '../components/materials/Material'

const Materials = ({ data, ...props }) => {
  const {
    claimTop,
    claimBottom,
    claimGallery,
    materials,
  } = data.dis.pageMaterial

  return (
    <div>
      <SEO
        title={data.seoTitle}
        description={data.seoDescription}
        image={data.seoImage}
      />
      
      <Top text1={claimTop} text2={claimBottom} images={claimGallery} />

      {materials &&
        materials.map((mat, i) => {
          return (
            <Material
              key={mat.name}
              i={('0' + (i + 1).toString()).slice(-2)}
              title={mat.name}
              subtitle={mat.subtitle}
              loopVideo={mat.video}
              video={mat.video}
              // video={mat.videoLoopSourceDesktop}
              videoMobile={mat.videoMobile}
              // videoMobile={mat.videoLoopSourceMobile}
              lead={mat.lead}
              mainText={mat.text}
              linkLabel={mat.moreCtaText}
              linkUrl={mat.moreCtaUrl}
              image={mat.backgroundImage}
            />
          )
        })}
    </div>
  )
}

export const query = graphql`
  query MaterialsQuery {
    dis {
      pageMaterial(where: { id: "cki5x7mk05nqg0a55lrxua28v" }) {
        pageTitle
        claimTop
        claimBottom
        claimGallery {
          handle
          width
          height
        }
        materials {
          ... on DIS_Material {
            name
            moreCtaText
            moreCtaUrl
            lead
            text
            subtitle
            video {
              url
            }
            videoMobile {
              url
            }
            videoLoopSourceDesktop
            videoLoopSourceMobile
            backgroundImage {
              handle
              width
              height
            }
          }
        }
        seoDescription
        seoImage {
          url
        }
        seoTitle
      }
    }
  }
`

export default Materials
