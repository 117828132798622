import React from 'react'
import styled from 'styled-components'
import GraphImg from 'graphcms-image'
import { useMediaQuery } from 'react-responsive'

import StickyWrapper from '../shared/StickyWrapper'
import ImgVideo from '../shared/ImgVideo'

import H from '../styled/Heading'
import P from '../styled/Paragraph'
import W from '../styled/Wrapper'
import L from '../styled/Link'

const VideoWrapper = styled.div`
  padding: 120px 0 120px;
  position: relative;
  background-color: ${({ theme }) => theme.white};

  @media only screen and (min-width: 768px) {
    padding-top: 90px;
  }

  @media only screen and (min-width: 1024px) {
    min-height: 100vh;
    padding: 0;
    margin-bottom: 100px;
  }
`

const TitleWrapper = styled(W)`
  text-align: center;
  padding-top: 40px;
  padding-bottom: 40px;

  @media only screen and (min-width: 768px) {
    padding-bottom: 90px;
  }

  @media only screen and (min-width: 1024px) {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    width: 100%;
    color: ${({ theme }) => theme.white};
    padding-bottom: 40px;
  }
`

const MaxWidth = styled.div`
  max-width: ${({ theme }) => theme.maxWidth};
  margin: 0 auto;
`

const Title = styled(H)`
  margin-bottom: 10px;
`
const Subtitle = styled(P)`
  margin-bottom: 10px;
`

const Wrapper = styled(W)`
  min-height: 100vh;
  background-color: ${({ theme }) => theme.white};
  position: relative;
  padding-top: 70px;
  padding-bottom: 50px;
  border-bottom: 1px solid ${({ theme }) => theme.black};

  @media only screen and (min-width: 768px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 80vh;
  }

  @media only screen and (min-width: 1024px) {
    min-height: 100vh;
    display: grid;
    align-content: center;
  }
`

const Lead = styled(P)`
  margin-bottom: 50px;
  position: relative;
  z-index: 1;

  @media only screen and (min-width: 1024px) {
    max-width: 90%;
    margin-bottom: 45px;
  }
`
const TextWrapper = styled.div`
  padding-bottom: 55vw;

  @media only screen and (min-width: 768px) {
    width: 85%;
    margin-left: 15%;
    padding-bottom: 0;
  }

  @media only screen and (min-width: 1024px) {
    width: 75%;
  }
`
const MainText = styled(P)`
  margin-bottom: 50px;
  position: relative;
  z-index: 1;

  @media only screen and (min-width: 1024px) {
    margin-bottom: 35px;
  }
`

const Link = styled(L)`
  position: relative;
  z-index: 1;
`

const Img = styled.div`
  position: absolute;
  bottom: 0;
  height: 100vw;
  width: 90%;
  z-index: 0;
  max-height: calc(100vh);

  @media only screen and (min-width: 1024px) {
    width: 50vw;
    height: 50vw;
    left: 50%;
    transform: translate(-50%);
    max-height: calc(100vh - 50px);
    max-width: 800px;
  }
`

const Material = ({
  i,
  title,
  subtitle,
  loopVideo,
  video,
  videoMobile,
  lead,
  mainText,
  linkLabel,
  linkUrl,
  image,
}) => {

  const isDt = useMediaQuery({
    query: '(min-width: 1024px)',
  })


  return (
    <React.Fragment>
      <StickyWrapper noMob scrollTo>
        <VideoWrapper>
          <div id={`d-${i}`}></div>
          <TitleWrapper>
            <Title size={1}>{`${i}. ${title}`}</Title>
            <Subtitle size="m">{subtitle}</Subtitle>
          </TitleWrapper>
          <ImgVideo loop={isDt ? video : videoMobile} sound fullHeight timelineBelow />
        </VideoWrapper>
      </StickyWrapper>
      <StickyWrapper scrollTo>
        <div id={`l-${i}`}></div>
        <Wrapper>
          <MaxWidth>
            <Lead size="l">{lead}</Lead>
            <TextWrapper>
              <MainText size="m">{mainText}</MainText>
              <Link href={linkUrl} target="_blank" rel="noopener noreferrer">
                {linkLabel}
              </Link>
            </TextWrapper>
            <Img className="no-placeholder">
              <GraphImg
                image={image}
                backgroundColor={'transparent'}
                maxWidth={400}
                blurryPlaceholder={false}
              />
            </Img>
          </MaxWidth>
        </Wrapper>
      </StickyWrapper>
    </React.Fragment>
  )
}

export default Material
